var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container order-list-all" },
    [
      _c("OrderFilterSelector", {
        ref: "orderFilterRef",
        attrs: { type: "全部工单", actived: _vm.activeTab },
        on: { filterChange: _vm.filterChangeHandler },
      }),
      _c("div", { staticClass: "filter-line" }, [
        _c(
          "span",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.newOrderHandler },
              },
              [_vm._v("新建工单")]
            ),
            _c("el-button", { on: { click: _vm.exportOrderHandler } }, [
              _vm._v("导出"),
            ]),
            _c(
              "el-button",
              {
                attrs: { plain: "", icon: "el-icon-refresh-right" },
                on: { click: _vm.freshHandler },
              },
              [_vm._v("刷新")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "header-filter-button",
            on: {
              click: function ($event) {
                _vm.dataset.filterLine.show = !_vm.dataset.filterLine.show
              },
            },
          },
          [
            _vm._v("\n      筛选\n      "),
            _vm.dataset.filterLine.show
              ? _c("i", { staticClass: "el-icon-arrow-down" })
              : _c("i", { staticClass: "el-icon-arrow-right" }),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c("FinalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
            scopedSlots: _vm._u([
              {
                key: "projectNameSlot",
                fn: function () {
                  return [
                    _c("el-cascader", {
                      ref: "cascaderHandle",
                      attrs: {
                        options: _vm.companyProjectList,
                        props: { checkStrictly: true },
                        clearable: "",
                      },
                      on: { change: _vm.companyProjectChange },
                      model: {
                        value: _vm.companyOrProject,
                        callback: function ($$v) {
                          _vm.companyOrProject = $$v
                        },
                        expression: "companyOrProject",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "configNameSlot",
                fn: function () {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          disabled: _vm.companyOrProject.length < 2,
                          placeholder:
                            _vm.companyOrProject.length < 2
                              ? "请先选择项目名称"
                              : "",
                        },
                        on: { change: _vm.resetCurrentAndGetTableList },
                        model: {
                          value: _vm.listQuery.orderConfigId,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "orderConfigId", $$v)
                          },
                          expression: "listQuery.orderConfigId",
                        },
                      },
                      _vm._l(_vm.configList, function (item) {
                        return _c("el-option", {
                          key: item.orderConfigId,
                          attrs: {
                            label: item.configName,
                            value: item.orderConfigId,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "sourceTypeSlot",
                fn: function () {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          clearable: "",
                          disabled: _vm.companyOrProject.length < 2,
                          placeholder:
                            _vm.companyOrProject.length < 2
                              ? "请先选择项目名称"
                              : "",
                        },
                        on: { change: _vm.resetCurrentAndGetTableList },
                        model: {
                          value: _vm.listQuery.applyTypeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "applyTypeId", $$v)
                          },
                          expression: "listQuery.applyTypeId",
                        },
                      },
                      _vm._l(_vm.applyArrs, function (item) {
                        return _c("el-option", {
                          key: item.applyTypeId,
                          attrs: {
                            label: item.applyTypeName,
                            value: item.applyTypeId,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "positionSearch",
                fn: function () {
                  return [
                    _c("tree-select", {
                      attrs: {
                        miltiple: false,
                        "show-count": true,
                        placeholder: "",
                        options: _vm.editPositionList,
                      },
                      on: { input: _vm.positionIdChange },
                      model: {
                        value: _vm.listQuery.positionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery, "positionId", $$v)
                        },
                        expression: "listQuery.positionId",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "submitUserSearch",
                fn: function () {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          placeholder: "",
                          clearable: "",
                        },
                        on: { change: _vm.resetCurrentAndGetTableList },
                        model: {
                          value: _vm.listQuery.reportUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "reportUserId", $$v)
                          },
                          expression: "listQuery.reportUserId",
                        },
                      },
                      _vm._l(_vm.employeeList, function (item) {
                        return _c(
                          "el-option",
                          {
                            key: item.userId,
                            attrs: { label: item.userName, value: item.userId },
                          },
                          [
                            _c("span", { staticStyle: { float: "left" } }, [
                              _vm._v(_vm._s(item.userName)),
                            ]),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  float: "right",
                                  color: "#8492a6",
                                  "font-size": "10px",
                                },
                              },
                              [_vm._v(_vm._s(item.deptName))]
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "serviceUserSearch",
                fn: function () {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          placeholder: "",
                          clearable: "",
                        },
                        on: { change: _vm.resetCurrentAndGetTableList },
                        model: {
                          value: _vm.listQuery.handlePerson,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "handlePerson", $$v)
                          },
                          expression: "listQuery.handlePerson",
                        },
                      },
                      _vm._l(_vm.employeeList, function (item) {
                        return _c(
                          "el-option",
                          {
                            key: item.userId,
                            attrs: { label: item.userName, value: item.userId },
                          },
                          [
                            _c("span", { staticStyle: { float: "left" } }, [
                              _vm._v(_vm._s(item.userName)),
                            ]),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  float: "right",
                                  color: "#8492a6",
                                  "font-size": "10px",
                                },
                              },
                              [_vm._v(_vm._s(item.deptName))]
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "orderDetailSlot",
                fn: function (row) {
                  return [
                    _c(
                      "div",
                      {
                        class: [
                          [0, 1, 2, 3, 4].includes(row.datas.orderStatus) &&
                            "blue-span",
                          [6, 7].includes(row.datas.orderStatus) && "red-span",
                          [5, 8, 9].includes(row.datas.orderStatus) &&
                            "gray-span",
                          "order-status-span",
                        ],
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.OrderStatusMap[row.datas.orderStatus]
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    ),
                    _vm.deadLineRender(row.datas)
                      ? _c("div", [
                          _c("span", { staticClass: "dead-line-span" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.deadLineRender(row.datas)) +
                                "\n          "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "submitUserDetailSlot",
                fn: function (row) {
                  return [
                    row.datas.submitUserName
                      ? _c("div", [
                          _c("div", { staticClass: "submit-user-span" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.datas.submitUserName) +
                                "\n          "
                            ),
                          ]),
                          _c("div", { staticClass: "submit-dept-span" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.datas.submitUserDeptName) +
                                "\n          "
                            ),
                          ]),
                        ])
                      : _c("div", [_vm._v("-")]),
                  ]
                },
              },
              {
                key: "servicePeopleDetailSlot",
                fn: function (row) {
                  return [
                    _c(
                      "div",
                      [
                        [0, 1, 6, 8].includes(row.datas.orderStatus)
                          ? [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("order.unabsorbed"))),
                              ]),
                            ]
                          : [
                              _c("div", [
                                _c("div", { staticClass: "submit-user-span" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.datas.handlePersonName) +
                                      "\n              "
                                  ),
                                ]),
                                _c("div", { staticClass: "submit-dept-span" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.datas.resDeptName) +
                                      "\n              "
                                  ),
                                ]),
                              ]),
                            ],
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "orderCodeDetailSlot",
                fn: function (row) {
                  return [
                    _c("span", { staticClass: "order-code-span" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(row.datas.orderCode) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "serviceTypeSlot",
                fn: function () {
                  return [
                    _c(
                      "el-select",
                      {
                        style: { width: "100%" },
                        attrs: { placeholder: "", clearable: "" },
                        model: {
                          value:
                            _vm.dataset.advanceFilterConfig.serviceType.value,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dataset.advanceFilterConfig.serviceType,
                              "value",
                              $$v
                            )
                          },
                          expression:
                            "dataset.advanceFilterConfig.serviceType.value",
                        },
                      },
                      _vm._l(_vm.servTypeArrs, function (it, dex) {
                        return _c("el-option", {
                          key: dex,
                          attrs: { value: it.itemCode, label: it.itemName },
                        })
                      }),
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "reportDeptIdSlot",
                fn: function () {
                  return [
                    _c(
                      "el-select",
                      {
                        style: { width: "100%" },
                        attrs: {
                          disabled: _vm.companyOrProject.length === 0,
                          placeholder:
                            _vm.companyOrProject.length === 0
                              ? "请先选择项目名称"
                              : "",
                          clearable: "",
                        },
                        model: {
                          value:
                            _vm.dataset.advanceFilterConfig.reportDeptId.value,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dataset.advanceFilterConfig.reportDeptId,
                              "value",
                              $$v
                            )
                          },
                          expression:
                            "dataset.advanceFilterConfig.reportDeptId.value",
                        },
                      },
                      _vm._l(_vm.reportDeptList, function (it, dex) {
                        return _c("el-option", {
                          key: dex,
                          attrs: { value: it.deptId, label: it.deptName },
                        })
                      }),
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "resDeptIdSlot",
                fn: function () {
                  return [
                    _c(
                      "el-select",
                      {
                        style: { width: "100%" },
                        attrs: {
                          disabled: _vm.companyOrProject.length === 0,
                          placeholder:
                            _vm.companyOrProject.length === 0
                              ? "请先选择项目名称"
                              : "",
                          clearable: "",
                        },
                        model: {
                          value:
                            _vm.dataset.advanceFilterConfig.resDeptId.value,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dataset.advanceFilterConfig.resDeptId,
                              "value",
                              $$v
                            )
                          },
                          expression:
                            "dataset.advanceFilterConfig.resDeptId.value",
                        },
                      },
                      _vm._l(_vm.reportDeptList, function (it, dex) {
                        return _c("el-option", {
                          key: dex,
                          attrs: { value: it.deptId, label: it.deptName },
                        })
                      }),
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "deviceNameSlot",
                fn: function () {
                  return [
                    _c("el-input", {
                      attrs: { placeholder: "", clearable: "" },
                      model: {
                        value: _vm.dataset.advanceFilterConfig.deviceName.value,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dataset.advanceFilterConfig.deviceName,
                            "value",
                            $$v
                          )
                        },
                        expression:
                          "dataset.advanceFilterConfig.deviceName.value",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "orderLevelSlot",
                fn: function () {
                  return [
                    _c(
                      "el-select",
                      {
                        style: { width: "100%" },
                        attrs: { placeholder: "", clearable: "" },
                        model: {
                          value:
                            _vm.dataset.advanceFilterConfig.orderLevel.value,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dataset.advanceFilterConfig.orderLevel,
                              "value",
                              $$v
                            )
                          },
                          expression:
                            "dataset.advanceFilterConfig.orderLevel.value",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: {
                            value: "0",
                            label: _vm.$t("order.config.ordinary"),
                          },
                        }),
                        _c("el-option", {
                          attrs: {
                            value: "1",
                            label: _vm.$t("order.config.urgent"),
                          },
                        }),
                        _c("el-option", {
                          attrs: {
                            value: "2",
                            label: _vm.$t("order.config.alarm"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "projectNameFilterSlot",
                fn: function () {
                  return [
                    _c("el-cascader", {
                      ref: "filterCascaderHandle",
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "",
                        options: _vm.companyProjectList,
                        props: { checkStrictly: true },
                        clearable: "",
                      },
                      on: { change: _vm.filterCompanyProjectChange },
                      model: {
                        value:
                          _vm.dataset.advanceFilterConfig.projectName.value,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dataset.advanceFilterConfig.projectName,
                            "value",
                            $$v
                          )
                        },
                        expression:
                          "dataset.advanceFilterConfig.projectName.value",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "transfered",
                fn: function () {
                  return [
                    _c(
                      "el-select",
                      {
                        style: { width: "100%" },
                        attrs: { placeholder: "", clearable: "" },
                        model: {
                          value:
                            _vm.dataset.advanceFilterConfig.isTransfer.value,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dataset.advanceFilterConfig.isTransfer,
                              "value",
                              $$v
                            )
                          },
                          expression:
                            "dataset.advanceFilterConfig.isTransfer.value",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { value: "", label: "全部" },
                        }),
                        _c("el-option", { attrs: { value: "Y", label: "是" } }),
                        _c("el-option", { attrs: { value: "N", label: "否" } }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "isPaid",
                fn: function () {
                  return [
                    _c(
                      "el-select",
                      {
                        style: { width: "100%" },
                        attrs: { placeholder: "", clearable: "" },
                        model: {
                          value: _vm.dataset.advanceFilterConfig.isPaid.value,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dataset.advanceFilterConfig.isPaid,
                              "value",
                              $$v
                            )
                          },
                          expression:
                            "dataset.advanceFilterConfig.isPaid.value",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { value: "", label: "全部" },
                        }),
                        _c("el-option", { attrs: { value: "Y", label: "是" } }),
                        _c("el-option", { attrs: { value: "N", label: "否" } }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "alarmIdSlot",
                fn: function () {
                  return [
                    _c("el-input", {
                      attrs: { placeholder: "", clearable: "" },
                      model: {
                        value: _vm.dataset.advanceFilterConfig.alarmId.value,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dataset.advanceFilterConfig.alarmId,
                            "value",
                            $$v
                          )
                        },
                        expression: "dataset.advanceFilterConfig.alarmId.value",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }