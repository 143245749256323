var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order-filter-selector" },
    _vm._l(_vm.currentFilters, function (item, index) {
      return _c(
        "span",
        {
          key: index,
          class: [
            "single-filter",
            item === _vm.defaultType && "single-filter-active",
            index === 0 && "single-filter-header",
            index === _vm.currentFilters.length - 1 && "single-filter-footer",
          ],
          on: {
            click: function ($event) {
              return _vm.setSingleActive(item)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.AllTypes[item]) + "\n  ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }