<template>
  <div class="app-container order-list-all">
    <OrderFilterSelector
      ref="orderFilterRef"
      type="全部工单"
      :actived="activeTab"
      @filterChange="filterChangeHandler"
    />
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="newOrderHandler">新建工单</el-button>
        <el-button @click="exportOrderHandler">导出</el-button>
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div class="table-container">
      <FinalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:projectNameSlot>
          <el-cascader
            ref="cascaderHandle"
            v-model="companyOrProject"
            :options="companyProjectList"
            :props="{ checkStrictly: true }"
            @change="companyProjectChange"
            clearable
          ></el-cascader>
        </template>
        <template v-slot:configNameSlot>
          <el-select
            clearable
            :disabled="companyOrProject.length < 2"
            v-model="listQuery.orderConfigId"
            @change="resetCurrentAndGetTableList"
            :placeholder="companyOrProject.length < 2 ? '请先选择项目名称' : ''"
          >
            <el-option
              v-for="item in configList"
              :key="item.orderConfigId"
              :label="item.configName"
              :value="item.orderConfigId"
            >
            </el-option>
          </el-select>
        </template>
        <template v-slot:sourceTypeSlot>
          <el-select
            clearable
            :disabled="companyOrProject.length < 2"
            v-model="listQuery.applyTypeId"
            @change="resetCurrentAndGetTableList"
            :placeholder="companyOrProject.length < 2 ? '请先选择项目名称' : ''"
          >
            <el-option
              v-for="item in applyArrs"
              :key="item.applyTypeId"
              :label="item.applyTypeName"
              :value="item.applyTypeId"
            >
            </el-option>
          </el-select>
        </template>
        <template v-slot:positionSearch>
          <tree-select
            v-model="listQuery.positionId"
            :miltiple="false"
            :show-count="true"
            placeholder=""
            :options="editPositionList"
            @input="positionIdChange"
          />
        </template>
        <template v-slot:submitUserSearch>
          <el-select
            filterable
            placeholder=""
            v-model="listQuery.reportUserId"
            clearable
            @change="resetCurrentAndGetTableList"
          >
            <el-option
              v-for="item in employeeList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            >
              <span style="float: left">{{ item.userName }}</span>
              <span style="float: right; color: #8492a6; font-size: 10px">{{
                item.deptName
              }}</span>
            </el-option>
          </el-select>
        </template>
        <template v-slot:serviceUserSearch>
          <el-select
            filterable
            placeholder=""
            v-model="listQuery.handlePerson"
            clearable
            @change="resetCurrentAndGetTableList"
          >
            <el-option
              v-for="item in employeeList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            >
              <span style="float: left">{{ item.userName }}</span>
              <span style="float: right; color: #8492a6; font-size: 10px">{{
                item.deptName
              }}</span>
            </el-option>
          </el-select>
        </template>
        <!--   1: this.$t("order.waitReceive") 待领取,
          //     2: this.$t("order.waitExecute") 待执行,
          //     3: this.$t("order.executing") 执行中,
          //     4: this.$t("order.waitAcceptance") 待验收,
          //     5: this.$t("order.completed") 已完成,
          //     6: this.$t("order.applyReject") 报单被驳回,
          //     7: this.$t("order.acceptanceReject") 验收被驳回,
          //     9: this.$t("order.alreadyCloseOrder") 已关单, -->
        <!-- 蓝字：2/3/4/1
          红字：6/7
          灰字：5/9 -->
        <template v-slot:orderDetailSlot="row">
          <div
            :class="[
              [0, 1, 2, 3, 4].includes(row.datas.orderStatus) && 'blue-span',
              [6, 7].includes(row.datas.orderStatus) && 'red-span',
              [5, 8, 9].includes(row.datas.orderStatus) && 'gray-span',
              'order-status-span',
            ]"
          >
            <span>
              {{ OrderStatusMap[row.datas.orderStatus] }}
            </span>
          </div>
          <div v-if="deadLineRender(row.datas)">
            <span class="dead-line-span">
              {{ deadLineRender(row.datas) }}
            </span>
          </div>
        </template>
        <template v-slot:submitUserDetailSlot="row">
          <div v-if="row.datas.submitUserName">
            <div class="submit-user-span">
              {{ row.datas.submitUserName }}
            </div>
            <div class="submit-dept-span">
              {{ row.datas.submitUserDeptName }}
            </div>
          </div>
          <div v-else>-</div>
        </template>
        <template v-slot:servicePeopleDetailSlot="row">
          <div>
            <template v-if="[0, 1, 6, 8].includes(row.datas.orderStatus)">
              <span>{{ $t("order.unabsorbed") }}</span>
            </template>
            <!-- <template v-else-if="row.datas.orderStatus === 6">
              <div v-if="row.datas.isSelf == 'Y'">
                <div class="submit-user-span">
                  {{ row.datas.submitUserName }}
                </div>
                <div class="submit-dept-span">
                  {{ row.datas.submitUserDeptName }}
                </div>
              </div>
              <span v-else>{{ $t("order.unabsorbed") }}</span>
            </template> -->
            <template v-else>
              <div>
                <div class="submit-user-span">
                  {{ row.datas.handlePersonName }}
                </div>
                <div class="submit-dept-span">
                  {{ row.datas.resDeptName }}
                </div>
              </div>
            </template>
          </div>
        </template>
        <template v-slot:orderCodeDetailSlot="row">
          <span class="order-code-span">
            {{ row.datas.orderCode }}
          </span>
        </template>
        <template v-slot:serviceTypeSlot>
          <el-select
            v-model="dataset.advanceFilterConfig.serviceType.value"
            placeholder=""
            clearable
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="(it, dex) in servTypeArrs"
              :key="dex"
              :value="it.itemCode"
              :label="it.itemName"
            ></el-option>
          </el-select>
        </template>
        <template v-slot:reportDeptIdSlot>
          <el-select
            :disabled="companyOrProject.length === 0"
            v-model="dataset.advanceFilterConfig.reportDeptId.value"
            :placeholder="
              companyOrProject.length === 0 ? '请先选择项目名称' : ''
            "
            clearable
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="(it, dex) in reportDeptList"
              :key="dex"
              :value="it.deptId"
              :label="it.deptName"
            ></el-option>
          </el-select>
        </template>
        <template v-slot:resDeptIdSlot>
          <el-select
            :disabled="companyOrProject.length === 0"
            v-model="dataset.advanceFilterConfig.resDeptId.value"
            :placeholder="
              companyOrProject.length === 0 ? '请先选择项目名称' : ''
            "
            clearable
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="(it, dex) in reportDeptList"
              :key="dex"
              :value="it.deptId"
              :label="it.deptName"
            ></el-option>
          </el-select>
        </template>
        <template v-slot:deviceNameSlot>
          <el-input
            v-model="dataset.advanceFilterConfig.deviceName.value"
            placeholder=""
            clearable
          >
          </el-input>
        </template>
        <template v-slot:orderLevelSlot>
          <el-select
            v-model="dataset.advanceFilterConfig.orderLevel.value"
            placeholder=""
            clearable
            :style="{ width: '100%' }"
          >
            <el-option
              value="0"
              :label="$t('order.config.ordinary')"
            ></el-option>
            <el-option value="1" :label="$t('order.config.urgent')"></el-option>
            <el-option value="2" :label="$t('order.config.alarm')"></el-option>
          </el-select>
        </template>
        <template v-slot:projectNameFilterSlot>
          <el-cascader
            ref="filterCascaderHandle"
            placeholder=""
            v-model="dataset.advanceFilterConfig.projectName.value"
            :options="companyProjectList"
            :props="{ checkStrictly: true }"
            @change="filterCompanyProjectChange"
            clearable
            style="width: 100%"
          ></el-cascader>
        </template>
        <template v-slot:transfered>
          <el-select
            v-model="dataset.advanceFilterConfig.isTransfer.value"
            placeholder=""
            clearable
            :style="{ width: '100%' }"
          >
            <el-option value="" label="全部"></el-option>
            <el-option value="Y" label="是"></el-option>
            <el-option value="N" label="否"></el-option>
          </el-select>
        </template>
        <template v-slot:isPaid>
          <el-select
            v-model="dataset.advanceFilterConfig.isPaid.value"
            placeholder=""
            clearable
            :style="{ width: '100%' }"
          >
            <el-option value="" label="全部"></el-option>
            <el-option value="Y" label="是"></el-option>
            <el-option value="N" label="否"></el-option>
          </el-select>
        </template>
        
        <template v-slot:alarmIdSlot>
          <el-input
            v-model="dataset.advanceFilterConfig.alarmId.value"
            placeholder=""
            clearable
          >
          </el-input>
        </template>
        
        
        
        
        
      </FinalTable>
    </div>
  </div>
</template>

<script>
import FinalTable from "@/components/FinalTable";
import OrderFilterSelector from "@/components/OrderFilterSelector";
import { listToTree } from "@/utils/tree";
import {
  getPosition,
  getEmployeeList,
  getOrderPage,
  findCompanyProjectList,
  getConfigList,
  getReportDeptList,
  exportOrder,
  exportAssistOrder,
  getCurrentUserInfo,
  assistedList,
} from "@/api/business/order/tenant/order";
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
const OrderStatusMap = {
  0: "报单待审核",
  1: "待领取",
  2: "待执行",
  3: "执行中",
  4: "待验收",
  5: "已完成",
  6: "报单被驳回",
  7: "验收被驳回",
  8: "报单已撤销",
  9: "已关单",
};
export default {
  name: "orderListAll",
  components: {
    FinalTable,
    OrderFilterSelector,
    TreeSelect,
  },
  data() {
    return {
      OrderStatusMap,
      activeTab: "",
      assistantFlag: false,
      applyArrs:[],
      listQuery: {
        positionId: null,
        reportStartDate: null,
        reportEndDate: null,
        reportUserId: null,
        handlePerson: null,
        serviceType: null,
        companyId: null,
        projectId: null,
        reportDeptId: null,
        resDeptId: null,
        deviceName: null,
        alarmId: null,
        problemDesc: null,
        orderStatus: null,
        orderConfigId: null,
        orderCode: null,
        applyTypeId:null,
        current: 1,
        rowCount: 10,
        isOverTime: "",
      },
      loadingFlag: false,
      employeeList: [],
      editPositionList: [],
      servTypeArrs: [],
      configList: [],
      companyProjectList: [],
      companyOrProject: [],
      reportDeptList: [],
      userInfo: {
        userId: null,
        deptId: null,
        companyId: null,
      },
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          {
            prop: "projectName",
            label: this.$t("base.project.name"),
            width: "",
          },
          {
            prop: "applyTypeName",
            label: '工单类型',
            width: "",
          },
          {
            prop: "configName",
            label: this.$t("order.orderConfigName"),
            width: "",
          },
          {
            prop: "positionName",
            label: this.$t("base.position.name"),
            width: "",
          },
          {
            prop: "problemDesc",
            label: this.$t("order.faultDesc"),
            width: "",
          },
          {
            prop: "orderStatus",
            label: this.$t("order.status"),
            width: "208",
          },
          {
            prop: "creationDate",
            label: this.$t("order.declareTime"),
            width: "",
          },
          {
            prop: "submitUserName",
            label: this.$t("order.declarePeople"),
            width: "",
          },
          {
            prop: "servicePeople",
            label: this.$t("order.servicePeople"),
            width: "",
          },
          {
            prop: "orderCode",
            label: this.$t("order.orderCode"),
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "120",
          },
        ],
        tableData: [],
        searchLineConfig: {
          projectName: {
            type: "slot",
            slotName: "projectNameSlot",
          },
          applyTypeName: {
            type: "slot",
            slotName: "sourceTypeSlot",
          },
          configName: {
            type: "slot",
            slotName: "configNameSlot",
          },
          positionName: {
            type: "slot",
            slotName: "positionSearch",
          },
          problemDesc: {
            type: "input",
            label: "问题",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入问题描述",
            prefixIcon: "el-icon-search",
          },
          orderStatus: {
            type: "selector",
            label: "工单状态",
            value: [],
            multiple: true,
            collapseTags: true,
            placeholder: "请选择工单状态",
            actionType: "goSearch",
            optionList: [
              {
                value: "0",
                label: "报单待审核",
              },
              {
                value: "6",
                label: this.$t("order.applyReject"),
              },
              {
                value: "1",
                label: this.$t("order.waitReceive"),
              },
              {
                value: "2",
                label: this.$t("order.waitExecute"),
              },
              {
                value: "3",
                label: this.$t("order.executing"),
              },
              {
                value: "4",
                label: this.$t("order.waitAcceptance"),
              },
              {
                value: "5",
                label: this.$t("order.completed"),
              },
              {
                value: "7",
                label: this.$t("order.acceptanceReject"),
              },
              {
                value: "8",
                label: "报单已撤销",
              },
              {
                value: "9",
                label: this.$t("order.alreadyCloseOrder"),
              },
            ],
          },
          submitUserName: {
            type: "slot",
            slotName: "submitUserSearch",
          },
          servicePeople: {
            type: "slot",
            slotName: "serviceUserSearch",
          },
          orderCode: {
            type: "input",
            label: "工单编码",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入工单编码",
            prefixIcon: "el-icon-search",
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          creationDate: {
            type: "dateFormat",
          },
          orderStatus: {
            type: "slot",
            slotName: "orderDetailSlot",
          },
          submitUserName: {
            type: "slot",
            slotName: "submitUserDetailSlot",
          },
          servicePeople: {
            type: "slot",
            slotName: "servicePeopleDetailSlot",
          },
          orderCode: {
            type: "slot",
            slotName: "orderCodeDetailSlot",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "详情",
              },
              {
                actionType: "iconClick",
                eventName: "more",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "领取工单",
                showProp: "getOrderFlag",
                showValue: true,
              },
            ],
          },
        },
        advanceFilterConfig: {
          projectName: {
            type: "slot",
            slotName: "projectNameFilterSlot",
            special: true,
            value: [],
            label: "项目名称",
          },
          reportDeptId: {
            type: "slot",
            slotName: "reportDeptIdSlot",
            value: "",
            label: "报单部门",
          },
          resDeptId: {
            type: "slot",
            slotName: "resDeptIdSlot",
            value: "",
            label: "责任部门",
          },
          serviceType: {
            type: "slot",
            slotName: "serviceTypeSlot",
            value: "",
            label: "服务类型",
          },
          deviceName: {
            type: "slot",
            slotName: "deviceNameSlot",
            value: "",
            label: "设备名称",
          },
          orderLevel: {
            type: "slot",
            slotName: "orderLevelSlot",
            value: "",
            label: "工单级别",
          },
          creationDate: {
            type: "dateRange",
            label: "报单时间",
            value: [],
          },
          isTransfer: {
            type: "slot",
            slotName: "transfered",
            value: "",
            label: "曾转派",
          },
          isPaid: {
             	type: "slot",
            	slotName: "isPaid",
            	value: "",
            	label: "有偿工单",
          },
          alarmId: {
            type: "slot",
            slotName: "alarmIdSlot",
            value: "",
            label: "告警ID",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
    };
  },
  computed: {
    orderAddOk() {
      return this.$store.state.app.orderUpdate;
    },
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
    orderAddOk: {
      deep: true,
      handler(v, n) {
        this.resetCurrentAndGetTableList();
      },
    },
  },
  created() {
    this.initDatas();
  },
  methods: {
    getUrlQuerys() {
      //获取连接上携带的参数
      const qData = this.$route.query;
      for (let key in qData) {
        qData[key] && this.updateSingleKey(key, qData);
      }
      this.getTableList();
    },
    updateSingleKey(key, datas) {
      const value = datas[key];
      switch (key) {
        case "statuArrs":
          this.listQuery.orderStatus = JSON.parse(value);
          this.dataset.searchLineConfig.orderStatus.value = JSON.parse(value);
          break;
        case "startDate":
          const { startDate, endDate } = this.$route.query;
          if (startDate && endDate) {
            this.dataset.advanceFilterConfig.creationDate.value = [
              startDate,
              endDate,
            ];
            this.listQuery.reportStartDate = new Date(startDate).getTime();
            this.listQuery.reportEndDate = new Date(endDate).getTime();
          }
          break;
        case "companyId":
          this.listQuery.companyId = Number(value);
          this.companyOrProject = [Number(value)];
          this.dataset.advanceFilterConfig.projectName.value = [Number(value)];
          this.initReportDeptList(value);
          this.dataset.advanceFilterConfig.projectName.value = [Number(value)];
          break;
        case "projectId":
          this.listQuery.projectId = value;
          this.companyOrProject = [Number(datas["companyId"]), Number(value)];
          this.dataset.advanceFilterConfig.projectName.value = [
            Number(datas["companyId"]),
            Number(value),
          ];
          this.initConfigList(value);
          break;
        case "orderConfigId":
          this.listQuery.orderConfigId = Number(value);
          break;
        case "positionId":
          this.listQuery.positionId = Number(value);
          break;
        case "problemDesc":
          this.listQuery.problemDesc = value;
          this.dataset.searchLineConfig.problemDesc.value = value;
          break;
        case "orderStatus":
          this.dataset.searchLineConfig.orderStatus.value = value.split(",");
          this.listQuery.orderStatus = value.split(",");
          break;
        case "reportUserId":
          this.listQuery.reportUserId = Number(value);
          break;
        case "handlePerson":
          this.listQuery.handlePerson = Number(value);
          break;
        case "orderCode":
          this.listQuery.orderCode = value;
          this.dataset.searchLineConfig.orderCode.value = value;
          break;
        case "isOverTime":
          this.listQuery.isOverTime = value;
          if (value == "Y") {
            console.log(999999999999, value);
            this.activeTab = "6";
          }
          break;
        case "reportDeptId":
          this.listQuery.reportDeptId = value;
          this.dataset.advanceFilterConfig.reportDeptId.value = Number(value);
          break;
        case "resDeptId":
          this.listQuery.resDeptId = value;
          this.dataset.advanceFilterConfig.resDeptId.value = Number(value);
          break;
        case "serviceType":
          this.listQuery.serviceType = value;
          this.dataset.advanceFilterConfig.serviceType.value = value;
          break;
        case "deviceName":
          this.listQuery.deviceName = value;
          this.dataset.advanceFilterConfig.deviceName.value = value;
          break;
        case "orderLevel":
          this.listQuery.orderLevel = value;
          this.dataset.advanceFilterConfig.orderLevel.value = value;
          break;
        case "reportStartDate":
          this.dataset.advanceFilterConfig.creationDate.value = [
            Number(value),
            datas["reportEndDate"] ? Number(datas["reportEndDate"]) : null,
          ];
          this.listQuery.reportStartDate = value;
          // this.listQuery.reportEndDate = new Date(endDate).getTime();
          break;
        case "reportEndDate":
          this.dataset.advanceFilterConfig.creationDate.value = [
            datas["reportStartDate"] ? Number(datas["reportStartDate"]) : null,
            Number(value),
          ];
          this.listQuery.reportEndDate = value;
          // this.listQuery.reportEndDate = new Date(endDate).getTime();
          break;
        case "isTransfer":
          this.dataset.advanceFilterConfig.isTransfer.value = value;
          this.listQuery.isTransfer = value;
          // this.listQuery.reportEndDate = new Date(endDate).getTime();
          break;
        case "isPaid":
          this.dataset.advanceFilterConfig.isPaid.value =value ;
          this.listQuery.isPaid = value;
          // this.listQuery.reportEndDate = new Date(endDate).getTime();
          break;
      	case "alarmId":
          this.listQuery.alarmId = value;
          this.dataset.advanceFilterConfig.alarmId.value = value;
          break;
          
      }
      this.$nextTick(() => {
        this.$refs.finalTableRef.freshCount();
      });
    },
    toIOSDateString(s) {
      return s.replace(/-/g, "/") || undefined;
    },
    timediff(startDate, endDate) {
      let date3 = endDate.getTime() - startDate.getTime(); //时间差的毫秒数
      //计算出相差天数
      let days = Math.floor(date3 / (24 * 3600 * 1000));
      //计算出小时数
      let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      let hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      let minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      let seconds = Math.round(leave3 / 1000);
      if (
        (days && days > 0) ||
        (hours && hours > 0) ||
        (minutes && minutes > 0)
      ) {
        return (
          (days > 0 ? days + "天" : "") +
          (hours > 0 ? hours + "小时" : "") +
          (minutes > 0 ? minutes + "分" : "")
        );
      } else {
        return 0 + "分";
      }
    },
    // 逻辑同小程序
    deadLineRender(data) {
      const orderStatus = data.orderStatus;
      // 1: 待领取、2：待执行、3：执行中
      switch (orderStatus) {
        case 1:
          if (
            !data.creationDateStr ||
            data.unclaimedNumber == null ||
            data.unclaimedOvertime == null
          ) {
            return "";
          }
          if (data.unclaimedNumber > 0) {
            // 超时
            const examine = data.orderDynamicList[0].creationDateStr;
            const formatExamine = new Date(this.toIOSDateString(examine));
            const examineMinute = new Date(
              formatExamine.setMinutes(
                formatExamine.getMinutes() + data.unclaimedOvertime
              )
            );
            return "超时" + this.timediff(examineMinute, new Date());
          } else {
            // 未超时
            return (
              "已生成" +
              this.timediff(
                new Date(this.toIOSDateString(data.creationDateStr)),
                new Date()
              )
            );
          }
          break;
        case 2:
          if (
            !data.requestDateStr ||
            !data.orderDynamicList[0].creationDateStr ||
            data.executedNumber == null ||
            data.firstExecutedOvertime == null
          ) {
            return "";
          }
          if (data.executedNumber > 0) {
            // 计算超时时间-ych
            let receiveMinute;
            let that = this;
            function getTimeByStatus(statusKey) {
              data.orderDynamicList.map((it) => {
                if (it.operationType === statusKey) {
                  if (data.executedNumber) {
                    const formatReceive = new Date(
                      that.toIOSDateString(it.creationDateStr)
                    );
                    receiveMinute = new Date(
                      formatReceive.setMinutes(
                        formatReceive.getMinutes() + data.firstExecutedOvertime
                      )
                    );
                  }
                }
              });
            }
            let findArr = ["receive", "report_audit_ok", "create"];
            findArr.map((its) => {
              if (!receiveMinute) getTimeByStatus(its);
            });
            if (!receiveMinute) return "";
            return "超时" + this.timediff(receiveMinute, new Date());
          } else {
            return (
              "剩余" +
              this.timediff(
                new Date(),
                new Date(this.toIOSDateString(data.requestDateStr))
              )
            );
          }
          break;
        case 3:
          if (!data.requestDateStr) {
            return "";
          }
          if (
            new Date().getTime() <
            new Date(this.toIOSDateString(data.requestDateStr)).getTime()
          ) {
            // 未超时
            return (
              "剩余" +
              this.timediff(
                new Date(),
                new Date(this.toIOSDateString(data.requestDateStr))
              )
            );
          } else {
            // 超时
            return (
              "超时" +
              this.timediff(
                new Date(this.toIOSDateString(data.requestDateStr)),
                new Date()
              )
            );
          }
          break;
        case 7:
          if (!data.requestDateStr) {
            return "";
          }
          if (
            new Date().getTime() <
            new Date(this.toIOSDateString(data.requestDateStr)).getTime()
          ) {
            // 未超时
            return (
              "剩余" +
              this.timediff(
                new Date(),
                new Date(this.toIOSDateString(data.requestDateStr))
              )
            );
          } else {
            // 超时
            return (
              "超时" +
              this.timediff(
                new Date(this.toIOSDateString(data.requestDateStr)),
                new Date()
              )
            );
          }
      }
    },
    exportOrderHandler() {
      const params = this.buildParams();
      if (this.assistantFlag) {
        exportAssistOrder(params).then((msg) => {
          this.$message({
            type: "success",
            message: this.$t("message.operationSuccess"),
          });
          let exportObj = {
            taskId: msg,
            taskName: "Order",
            taskStatus: 0,
            rootPath: "basePath",
          };
          //将导出任务丢入导出任务列表中
          this.$store.dispatch("PushExportNotice", exportObj);
        });
      } else {
        exportOrder(params).then((msg) => {
          this.$message({
            type: "success",
            message: this.$t("message.operationSuccess"),
          });
          let exportObj = {
            taskId: msg,
            taskName: "Order",
            taskStatus: 0,
            rootPath: "basePath",
          };
          //将导出任务丢入导出任务列表中
          this.$store.dispatch("PushExportNotice", exportObj);
        });
      }
    },
    initConfigList(projectId) {
      getConfigList({ projectId }).then((res) => {
        this.configList = res;
      });
    },
    filterCompanyProjectChange(value) {
      this.companyOrProject = value;
      this.listQuery.companyId = value[0];
      this.listQuery.projectId = value[1];
      this.listQuery.orderConfigId = "";
      if (this.$refs.filterCascaderHandle) {
        this.$refs.filterCascaderHandle.dropDownVisible = false;
      }
      if (value.length) {
        this.initReportDeptList(value[0]);
      }
      if (value.length === 2) {
        this.initConfigList(value[1]);
      }
    },
    companyProjectChange(value) {
      console.log("xxx1", value);
      this.dataset.advanceFilterConfig.projectName.value = value;
      this.listQuery.companyId = value[0];
      this.listQuery.projectId = value[1];
      this.listQuery.applyTypeId = "";
      this.listQuery.orderConfigId = "";
      this.$refs.cascaderHandle.dropDownVisible = false;
      this.resetCurrentAndGetTableList();
      if (value.length) {
        this.initReportDeptList(value[0]);
      }
      if (value.length === 2) {
        this.initConfigList(value[1]);
        this.initApplyTypes();
      }
      // 项目名称有变化则把高级筛选中的责任部门也清空并且重新计算角标数值
      this.dataset.advanceFilterConfig.resDeptId.value = "";
      this.$refs.finalTableRef.freshCount();
    },
    async initApplyTypes(){
      //获取工单来源类型查询
      let rqData={
        projectId:this.listQuery.projectId
      }
      let res=await this.ApiHttp('/order/tenant/orderConfig/getApplyTypeList',rqData);
      console.log('initApplyTypes',res)
      this.applyArrs=res||[];
    },
    initReportDeptList(companyId) {
      getReportDeptList({ companyId }).then((res) => {
        this.reportDeptList = res;
      });
    },
    async initServiceArrs() {
      //获取服务类型
      let res = await this.ApiHttp("/lookup/lookup/item/find", {
        isloading: false,
        classifyCode: "ORDER_SERVICE_TYPE",
      });
      if (res) {
        this.servTypeArrs = res.lookupItemList; //服务类型数据
      }
    },
    positionIdChange() {
      console.log("positionIdChange");
      this.$nextTick(() => {
        this.resetCurrentAndGetTableList();
      });
    },
    getPosition() {
      getPosition().then((res) => {
        let data = listToTree(res, "positionId", "parentId");
        this.recursivePosition(data);
      });
    },
    recursivePosition(row) {
      let data = row.map((v) => {
        v.label = v.positionName;
        v.id = v.positionId;
        if (v.children != undefined) {
          this.recursivePosition(v.children);
        }
        return v;
      });
      this.editPositionList = data;
    },
    filterChangeHandler(key) {
      this.clearAllFilters();
      switch (key) {
        case "1":
          // 全部 - 清除所有查询条件并查询
          this.getTableList();
          break;
        case "2":
          // 我提交的 - 清除所有查询条件，报单人员(reportUserId) = 当前登录人的userId
          this.listQuery.reportUserId = this.userInfo.userId;
          this.getTableList();
          break;
        case "3":
          // 待我领取 - 清除所有查询条件，公司、项目、责任部门设置成当前登录人所在的
          this.listQuery.resDeptId = this.userInfo.deptId;
          this.listQuery.orderStatus = ["1"];
          this.dataset.searchLineConfig.orderStatus.value = ["1"];
          this.dataset.advanceFilterConfig.resDeptId.value =
            this.userInfo.deptId;
          this.filterCompanyProjectChange([this.userInfo.companyId]);
          this.dataset.advanceFilterConfig.projectName.value = [
            this.userInfo.companyId,
          ];
          this.initReportDeptList(this.userInfo.companyId);
          this.getTableList();
          break;
        case "4":
          // 我执行的 - 清除所有查询条件，维修人员(handlePerson) = 当前登录人的userId
          this.listQuery.handlePerson = this.userInfo.userId;
          this.getTableList();
          break;
        case "5":
          // 我协助的 - 清除所有查询条件，切换查询接口
          this.assistantFlag = true;
          this.getTableList();
          break;
        case "6":
          // 我协助的 - 清除所有查询条件，切换查询接口
          this.listQuery.isOverTime = "Y";
          this.getTableList();
          break;
      }
      this.$nextTick(() => {
        this.$refs.finalTableRef.freshCount();
        this.resetCurrentAndGetTableList();
      });
    },
    clearAllFilters() {
      this.listQuery = {
        ...this.listQuery,
        ...{
          positionId: null,
          reportStartDate: null,
          reportEndDate: null,
          reportUserId: null,
          handlePerson: null,
          serviceType: null,
          companyId: null,
          projectId: null,
          reportDeptId: null,
          resDeptId: null,
          deviceName: null,
          problemDesc: null,
          orderConfigId: null,
          orderCode: null,
          current: 1,
          rowCount: 10,
          isOverTime: "",
        },
      };
      for (let key in this.dataset.advanceFilterConfig) {
        this.dataset.advanceFilterConfig[key].value = [
          "creationDate",
          "projectName",
        ].includes(key)
          ? []
          : "";
      }
      this.companyOrProject = [];
      this.listQuery.orderStatus = [];
      this.dataset.searchLineConfig.orderStatus.value = [];
      this.assistantFlag = false;
    },
    async initDatas() {
      await this.initUserInfo();
      this.getEmployeeList();
      this.getPosition();
      this.initServiceArrs();
      this.initCompanyProjectList();
      this.getUrlQuerys();
    },
    initUserInfo() {
      return new Promise((resolve) => {
        getCurrentUserInfo()
          .then((res) => {
            this.userInfo.userId = res.userId;
            this.userInfo.companyId = res.companyId;
            this.userInfo.deptId = res.deptId;
          })
          .finally(() => {
            resolve();
          });
      });
    },
    initCompanyProjectList() {
      findCompanyProjectList().then((res) => {
        res.forEach((company) => {
          company.label = company.companyName;
          company.value = company.companyId;
          company.projectChildren.length &&
            company.projectChildren.forEach((project) => {
              project.label = project.projectName;
              project.value = project.projectId;
            });
          company.children = company.projectChildren;
        });
        this.companyProjectList = res;
      });
    },
    getEmployeeList() {
      getEmployeeList().then((res) => {
        this.employeeList = res;
      });
    },
    resetCurrentAndGetTableList() {
      this.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      let str = "";
      let dataObj = { ...this.$route.query, ...this.listQuery };
      for (let keys in dataObj) {
        let val = dataObj[keys];
        if (keys && val) {
          str += keys + "=" + val + "&";
        }
      }
      this.$router.push(this.$route.path + "?" + str);
      this.getTableList();
    },
    buildParams() {
      let reqDatas = {
        ...this.listQuery,
      };
      delete reqDatas.orderStatus;
      if (this.listQuery.orderStatus && this.listQuery.orderStatus.length > 0) {
        reqDatas.orderStatusListStr = this.listQuery.orderStatus.join(";");
      }
      return reqDatas;
    },
    getTableList() {
      this.loadingFlag = true;
      for (let keys in this.listQuery) {
        if (this.listQuery[keys] === "") this.listQuery[keys] = null;
      }
      const reqDatas = this.buildParams();
      if (this.assistantFlag) {
        assistedList(reqDatas)
          .then((res) => {
            this.dataset.tableData = res.rows;
            this.dataset.pageVO.total = res.total;
          })
          .finally(() => {
            this.loadingFlag = false;
          });
      } else {
        window.sessionStorage.setItem("tbQuery", JSON.stringify(reqDatas));
        getOrderPage(reqDatas)
          .then((res) => {
            const resultList = res.rows.map((item) => {
              const { orderStatus, resDeptId } = item;
              item.getOrderFlag =
                orderStatus === 1 && resDeptId === this.userInfo.deptId;
              return item;
            });
            this.dataset.tableData = resultList;
            this.dataset.pageVO.total = res.total;
          })
          .finally(() => {
            this.loadingFlag = false;
          });
      }
    },
    dealTime(datas) {
      // 注册时间
      if (datas.params.creationDate && datas.params.creationDate.length > 0) {
        this.listQuery.reportStartDate = datas.params.creationDate[0]
          ? new Date(datas.params.creationDate[0]).getTime()
          : null;
        this.listQuery.reportEndDate = datas.params.creationDate[1]
          ? new Date(datas.params.creationDate[1]).getTime()
          : null;
        delete datas.params.creationDate;
      } else {
        if (
          datas.params.creationDate &&
          datas.params.creationDate.length === 0
        ) {
          this.listQuery.reportStartDate = "";
          this.listQuery.reportEndDate = "";
        }
      }
      return datas;
    },
    dealProjectName(datas) {
      if (datas.params.projectName) {
        this.companyOrProject = datas.params.projectName || [];
        this.listQuery.companyId = datas.params.projectName
          ? datas.params.projectName[0]
          : null;
        this.listQuery.projectId = datas.params.projectName
          ? datas.params.projectName[1]
          : null;
        delete datas.params.projectName;
      }
      return datas;
    },
    tableEventHandler(datas) {
      console.log("datas", datas);
      if (datas.type === "goSearch") {
        this.dealProjectName(datas);
        // 处理时间参数
        this.dealTime(datas);
        this.listQuery = { ...this.listQuery, ...datas.params };
        this.resetCurrentAndGetTableList();
      } else if (datas.type === "paginationChange") {
        this.listQuery.current = datas.params.current.page;
        this.listQuery.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.lookOrderDetail(datas.row);
            break;
          case "delete":
            this.deleteDevice(datas.row.deviceId);
            break;
          case "more":
            this.receiveOrder(datas.row);
            break;
        }
      }
    },

    async receiveOrder(row) {
      //领取工单
      let params = {
        orderId: row.orderId,
      };
      let res = await this.ApiHttp(
        "/order/tenant/orderFill/receiveOrder",
        params,
        "PUT"
      );
      if (res) {
        this.$message.info({ type: "success", message: "操作成功" });
        this.getTableList();
      }
    },

    lookOrderDetail(row) {
      //查看工单详情
      // 判断是bms还是rwork
      let params = {
        path: "/iot/orderQuery/detail",
        query: {
          orderId: row.orderId,
        },
      };
      if (location.hash.includes("/bms")) {
        params.path = "/bms/orderDetail";
      }
      this.$router.push(params);
    },
    newOrderHandler() {
      this.$store.commit("setOrderAddEdit", {
        open: true,
        type: "add",
        dataId: "",
      });
      //    this.$message.info("新建工单弹窗");
    },
    freshHandler() {
      this.resetCurrentAndGetTableList();
    },
  },
};
</script>

<style lang="less" scoped>
.order-list-all {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }

  .order-status-span {
    display: inline-block;
    height: 24px;
    line-height: 24px;
    padding: 0 6px;
    font-size: 14px;
  }
  .blue-span {
    color: #1a4cec;
    background: #f4f6fe;
  }
  .red-span {
    color: #e8331c;
    background: #fef5f4;
  }
  .gray-span {
    color: #8993a4;
    background: #f2f2f2;
  }
  .submit-user-span {
    font-size: 14px;
    font-weight: 400;
    color: #2f3941;
    line-height: 20px;
  }
  .submit-dept-span {
    font-size: 12px;
    font-weight: 400;
    color: #c0c4cc;
    line-height: 20px;
  }
  .order-code-span {
    color: #8993a4;
  }
  .dead-line-span {
    color: #ff0000;
    font-weight: 500;
    font-size: 12px;
    padding: 2px 6px;
    background: #ff000015;
    margin-top: 2px;
  }
}
</style>