<template>
  <div class="order-filter-selector">
    <span
      :class="[
        'single-filter',
        item === defaultType && 'single-filter-active',
        index === 0 && 'single-filter-header',
        index === currentFilters.length - 1 && 'single-filter-footer',
      ]"
      v-for="(item, index) in currentFilters"
      :key="index"
      @click="setSingleActive(item)"
    >
      {{ AllTypes[item] }}
    </span>
  </div>
</template>

<script>
// const OrderStatusMap = {
//   1: "待领取",
//   2: "待执行",
//   3: "执行中",
//   4: "待验收",
//   5: "已完成",
//   6: "报单被驳回",
//   7: "验收被驳回",
//   9: "已关单",
// };
const AllFilters = {
  全部工单: ["1", "2", "3", "4", "5", "6"],
  待领取: ["1", "2", "3"],
  待执行: ["1", "2", "4"],
  执行中: ["1", "2", "4", "5"],
  待验收: ["1", "2", "4", "5"],
  报单被驳回: ["1", "2"],
  报单已撤销: ["1", "2"],
  验收被驳回: ["4", "5"],
  已关单: ["1", "2", "4", "5"],
  已完成: ["1", "2", "4", "5"],
};
const AllTypes = {
  1: "全部",
  2: "我提交的",
  3: "待我领取",
  4: "我执行的",
  5: "我协助的",
  6: "已超时",
};
export default {
  name: "orderFilterSelector",
  props: {
    type: {
      type: String,
      default() {
        return "all";
      },
    },
    actived: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      AllTypes,
      defaultType: "1",
    };
  },
  watch: {
    type: {
      handler() {
        this.defaultType = this.currentFilters[0];
      },
      immediate: true,
    },
    actived: {
      handler() {
        if (this.actived) {
          //		  		this.setSingleActive(String(this.actived));
          this.defaultType = String(this.actived);
        }
      },
    },
  },
  computed: {
    currentFilters() {
      return AllFilters[this.type];
    },
  },

  methods: {
    setDefaultType(type) {
      console.log("xxx1");
      this.defaultType = type;
      this.$forceUpdate();
    },
    setSingleActive(item) {
      console.log(66666, item);
      this.defaultType = item;
      this.$emit("filterChange", item);
    },
  },
};
</script>

<style lang="less" scoped>
.order-filter-selector {
  margin-bottom: 20px;
  .single-filter {
    display: inline-block;
    height: 36px;
    line-height: 36px;
    color: #4e5969;
    border: 1px solid #e4e7ed;
    cursor: pointer;
    width: 96px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
  }
  .single-filter-active {
    color: #1a4cec;
    border: 1px solid #1a4cec;
  }
  .single-filter-header {
    border-radius: 5px 0 0 5px;
  }
  .single-filter-footer {
    border-radius: 0 5px 5px 0;
  }
}
</style>